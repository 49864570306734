// frontend/react-app/src/data/dataStore.js

const dataStore = {
  customer: {
    name: 'Shadow Realm Streetwear Pty Ltd',
    number: 'Customer #001',
  },
  jobs: [
    {
      id: 1001,
      date: '2024-10-01',
      description: 'Strange Summer 2025',
      workflow: [
        { name: 'Sample Check in', status: 'complete', progress: '20/20' },
        { name: 'Capture', status: 'complete', progress: '20/20' },
        { name: 'Retouch', status: 'inProgress', progress: '10/20' },
        { name: 'QA', status: 'notStarted', progress: '0/20' },
        { name: 'Approval', status: 'notStarted', progress: '0/20' },
        { name: 'Publish', status: 'notStarted', progress: '0/20' },
        { name: 'Sample Check out', status: 'notStarted', progress: '0/20' },
      ],
      products: [
        { name: 'Demogorgon T-Shirt', sku: 'ST-1001-1', status: 'Retouch' },
        { name: 'Hawkins Hoodie', sku: 'ST-1001-2', status: 'Retouch' },
        { name: 'Mindflayer Jacket', sku: 'ST-1001-3', status: 'Retouch' },
        { name: 'Upside Down Jeans', sku: 'ST-1001-4', status: 'Retouch' },
        { name: 'Eleven Sneakers', sku: 'ST-1001-5', status: 'Retouch' },
        { name: 'Stranger Cap', sku: 'ST-1001-6', status: 'Retouch' },
        { name: 'Fireball Pants', sku: 'ST-1001-7', status: 'Retouch' },
        { name: 'Hopper Vest', sku: 'ST-1001-8', status: 'Retouch' },
        { name: 'Eddie Guitar Tee', sku: 'ST-1001-9', status: 'Retouch' },
        { name: 'Vecna Sweater', sku: 'ST-1001-10', status: 'Retouch' },
        { name: 'Starlight Cape', sku: 'ST-1001-11', status: 'Capture' },
        { name: 'Eggo Beanie', sku: 'ST-1001-12', status: 'Capture' },
        { name: 'Starcourt Shorts', sku: 'ST-1001-13', status: 'Capture' },
        { name: 'Sinclair Boots', sku: 'ST-1001-14', status: 'Capture' },
        { name: 'Max Roller Skates', sku: 'ST-1001-15', status: 'Capture' },
        { name: 'Joyce Jumper', sku: 'ST-1001-16', status: 'Capture' },
        { name: 'Hellfire Hoodie', sku: 'ST-1001-17', status: 'Capture' },
        { name: 'Dustin Hat', sku: 'ST-1001-18', status: 'Capture' },
        { name: 'Lucas Tracksuit', sku: 'ST-1001-19', status: 'Capture' },
        { name: 'Will Vest', sku: 'ST-1001-20', status: 'Capture' },
      ],
    },
    // Job 1002
    {
      id: 1002,
      date: '2024-10-02',
      description: 'Upside Down Kidwear Collection',
      workflow: [
        { name: 'Sample Check in', status: 'complete', progress: '20/20' },
        { name: 'Capture', status: 'complete', progress: '20/20' },
        { name: 'Retouch', status: 'inProgress', progress: '15/20' },
        { name: 'QA', status: 'notStarted', progress: '0/20' },
        { name: 'Approval', status: 'notStarted', progress: '0/20' },
        { name: 'Publish', status: 'notStarted', progress: '0/20' },
        { name: 'Sample Check out', status: 'notStarted', progress: '0/20' },
      ],
      products: [
        { name: 'Demogorgon T-Shirt', sku: 'ST-1002-1', status: 'Retouch' },
        { name: 'Hawkins Hoodie', sku: 'ST-1002-2', status: 'Retouch' },
        { name: 'Mindflayer Jacket', sku: 'ST-1002-3', status: 'Retouch' },
        { name: 'Upside Down Jeans', sku: 'ST-1002-4', status: 'Retouch' },
        { name: 'Eleven Sneakers', sku: 'ST-1002-5', status: 'Retouch' },
        { name: 'Stranger Cap', sku: 'ST-1002-6', status: 'Retouch' },
        { name: 'Fireball Pants', sku: 'ST-1002-7', status: 'Retouch' },
        { name: 'Hopper Vest', sku: 'ST-1002-8', status: 'Retouch' },
        { name: 'Eddie Guitar Tee', sku: 'ST-1002-9', status: 'Retouch' },
        { name: 'Vecna Sweater', sku: 'ST-1002-10', status: 'Retouch' },
        { name: 'Starlight Cape', sku: 'ST-1002-11', status: 'Capture' },
        { name: 'Eggo Beanie', sku: 'ST-1002-12', status: 'Capture' },
        { name: 'Starcourt Shorts', sku: 'ST-1002-13', status: 'Capture' },
        { name: 'Sinclair Boots', sku: 'ST-1002-14', status: 'Capture' },
        { name: 'Max Roller Skates', sku: 'ST-1002-15', status: 'Capture' },
        { name: 'Joyce Jumper', sku: 'ST-1002-16', status: 'Capture' },
        { name: 'Hellfire Hoodie', sku: 'ST-1002-17', status: 'Capture' },
        { name: 'Dustin Hat', sku: 'ST-1002-18', status: 'Capture' },
        { name: 'Lucas Tracksuit', sku: 'ST-1002-19', status: 'Capture' },
        { name: 'Will Vest', sku: 'ST-1002-20', status: 'Capture' },
      ],
    },

    // Job 1003
    {
      id: 1003,
      date: '2024-10-03',
      description: "Vecna's Vintage Line",
      workflow: [
        { name: 'Sample Check in', status: 'complete', progress: '20/20' },
        { name: 'Capture', status: 'complete', progress: '20/20' },
        { name: 'Retouch', status: 'complete', progress: '20/20' },
        { name: 'QA', status: 'inProgress', progress: '8/20' },
        { name: 'Approval', status: 'notStarted', progress: '0/20' },
        { name: 'Publish', status: 'notStarted', progress: '0/20' },
        { name: 'Sample Check out', status: 'notStarted', progress: '0/20' },
      ],
      products: [
        { name: 'Demogorgon T-Shirt', sku: 'ST-1003-1', status: 'QA' },
        { name: 'Hawkins Hoodie', sku: 'ST-1003-2', status: 'QA' },
        { name: 'Mindflayer Jacket', sku: 'ST-1003-3', status: 'QA' },
        { name: 'Upside Down Jeans', sku: 'ST-1003-4', status: 'QA' },
        { name: 'Eleven Sneakers', sku: 'ST-1003-5', status: 'QA' },
        { name: 'Stranger Cap', sku: 'ST-1003-6', status: 'QA' },
        { name: 'Fireball Pants', sku: 'ST-1003-7', status: 'QA' },
        { name: 'Hopper Vest', sku: 'ST-1003-8', status: 'QA' },
        { name: 'Eddie Guitar Tee', sku: 'ST-1003-9', status: 'Retouch' },
        { name: 'Vecna Sweater', sku: 'ST-1003-10', status: 'Retouch' },
        { name: 'Starlight Cape', sku: 'ST-1003-11', status: 'Retouch' },
        { name: 'Eggo Beanie', sku: 'ST-1003-12', status: 'Retouch' },
        { name: 'Starcourt Shorts', sku: 'ST-1003-13', status: 'Retouch' },
        { name: 'Sinclair Boots', sku: 'ST-1003-14', status: 'Retouch' },
        { name: 'Max Roller Skates', sku: 'ST-1003-15', status: 'Retouch' },
        { name: 'Joyce Jumper', sku: 'ST-1003-16', status: 'Retouch' },
        { name: 'Hellfire Hoodie', sku: 'ST-1003-17', status: 'Retouch' },
        { name: 'Dustin Hat', sku: 'ST-1003-18', status: 'Retouch' },
        { name: 'Lucas Tracksuit', sku: 'ST-1003-19', status: 'Retouch' },
        { name: 'Will Vest', sku: 'ST-1003-20', status: 'Retouch' },
      ],
    },

    // Job 1004
    {
      id: 1004,
      date: '2024-10-04',
      description: 'Hawkins High Apparel',
      workflow: [
        { name: 'Sample Check in', status: 'complete', progress: '20/20' },
        { name: 'Capture', status: 'complete', progress: '20/20' },
        { name: 'Retouch', status: 'complete', progress: '20/20' },
        { name: 'QA', status: 'complete', progress: '20/20' },
        { name: 'Approval', status: 'inProgress', progress: '5/20' },
        { name: 'Publish', status: 'notStarted', progress: '0/20' },
        { name: 'Sample Check out', status: 'notStarted', progress: '0/20' },
      ],
      products: [
        { name: 'Demogorgon T-Shirt', sku: 'ST-1004-1', status: 'Approval' },
        { name: 'Hawkins Hoodie', sku: 'ST-1004-2', status: 'Approval' },
        { name: 'Mindflayer Jacket', sku: 'ST-1004-3', status: 'Approval' },
        { name: 'Upside Down Jeans', sku: 'ST-1004-4', status: 'Approval' },
        { name: 'Eleven Sneakers', sku: 'ST-1004-5', status: 'Approval' },
        { name: 'Stranger Cap', sku: 'ST-1004-6', status: 'QA' },
        { name: 'Fireball Pants', sku: 'ST-1004-7', status: 'QA' },
        { name: 'Hopper Vest', sku: 'ST-1004-8', status: 'QA' },
        { name: 'Eddie Guitar Tee', sku: 'ST-1004-9', status: 'QA' },
        { name: 'Vecna Sweater', sku: 'ST-1004-10', status: 'QA' },
        { name: 'Starlight Cape', sku: 'ST-1004-11', status: 'QA' },
        { name: 'Eggo Beanie', sku: 'ST-1004-12', status: 'QA' },
        { name: 'Starcourt Shorts', sku: 'ST-1004-13', status: 'QA' },
        { name: 'Sinclair Boots', sku: 'ST-1004-14', status: 'QA' },
        { name: 'Max Roller Skates', sku: 'ST-1004-15', status: 'QA' },
        { name: 'Joyce Jumper', sku: 'ST-1004-16', status: 'QA' },
        { name: 'Hellfire Hoodie', sku: 'ST-1004-17', status: 'QA' },
        { name: 'Dustin Hat', sku: 'ST-1004-18', status: 'QA' },
        { name: 'Lucas Tracksuit', sku: 'ST-1004-19', status: 'QA' },
        { name: 'Will Vest', sku: 'ST-1004-20', status: 'QA' },
      ],
    },

    // Job 1005
    {
      id: 1005,
      date: '2024-10-05',
      description: 'Eggo Extravaganza Collection',
      workflow: [
        { name: 'Sample Check in', status: 'complete', progress: '20/20' },
        { name: 'Capture', status: 'complete', progress: '20/20' },
        { name: 'Retouch', status: 'complete', progress: '20/20' },
        { name: 'QA', status: 'complete', progress: '20/20' },
        { name: 'Approval', status: 'complete', progress: '20/20' },
        { name: 'Publish', status: 'inProgress', progress: '10/20' },
        { name: 'Sample Check out', status: 'notStarted', progress: '0/20' },
      ],
      products: [
        { name: 'Demogorgon T-Shirt', sku: 'ST-1005-1', status: 'Publish' },
        { name: 'Hawkins Hoodie', sku: 'ST-1005-2', status: 'Publish' },
        { name: 'Mindflayer Jacket', sku: 'ST-1005-3', status: 'Publish' },
        { name: 'Upside Down Jeans', sku: 'ST-1005-4', status: 'Publish' },
        { name: 'Eleven Sneakers', sku: 'ST-1005-5', status: 'Publish' },
        { name: 'Stranger Cap', sku: 'ST-1005-6', status: 'Publish' },
        { name: 'Fireball Pants', sku: 'ST-1005-7', status: 'Publish' },
        { name: 'Hopper Vest', sku: 'ST-1005-8', status: 'Publish' },
        { name: 'Eddie Guitar Tee', sku: 'ST-1005-9', status: 'Publish' },
        { name: 'Vecna Sweater', sku: 'ST-1005-10', status: 'Publish' },
        { name: 'Starlight Cape', sku: 'ST-1005-11', status: 'Approval' },
        { name: 'Eggo Beanie', sku: 'ST-1005-12', status: 'Approval' },
        { name: 'Starcourt Shorts', sku: 'ST-1005-13', status: 'Approval' },
        { name: 'Sinclair Boots', sku: 'ST-1005-14', status: 'Approval' },
        { name: 'Max Roller Skates', sku: 'ST-1005-15', status: 'Approval' },
        { name: 'Joyce Jumper', sku: 'ST-1005-16', status: 'Approval' },
        { name: 'Hellfire Hoodie', sku: 'ST-1005-17', status: 'Approval' },
        { name: 'Dustin Hat', sku: 'ST-1005-18', status: 'Approval' },
        { name: 'Lucas Tracksuit', sku: 'ST-1005-19', status: 'Approval' },
        { name: 'Will Vest', sku: 'ST-1005-20', status: 'Approval' },
      ],
    },
  ],
};

// Helper functions to access the data

/**
 * Get the list of all jobs with basic details.
 * @returns {Array} List of jobs with id, date, and description.
 */
export function getJobList() {
  return dataStore.jobs.map(({ id, date, description }) => ({
    id,
    date,
    description,
  }));
}

/**
 * Get the workflow details for a specific job, including step status and progress.
 * @param {number} jobId - The ID of the job.
 * @returns {Array|null} Workflow steps with status and progress or null if job not found.
 */
export function getJobWorkflow(jobId) {
  const job = dataStore.jobs.find((job) => job.id === jobId);
  return job ? job.workflow : null;
}

/**
 * Get the status and progress of each step in a specific job's workflow.
 * @param {number} jobId - The ID of the job.
 * @returns {Array|null} Array of steps with names, statuses, and progress or null if job not found.
 */
export function getWorkflowStatus(jobId) {
  const workflow = getJobWorkflow(jobId);
  return workflow
    ? workflow.map((step) => ({
        name: step.name,
        status: step.status,
        progress: step.progress,
      }))
    : null;
}

/**
 * Get the list of products for a specific job, including each product’s name, SKU, and current status.
 * @param {number} jobId - The ID of the job.
 * @returns {Array|null} Array of products with name, SKU, and status or null if job not found.
 */
export function getJobProducts(jobId) {
  const job = dataStore.jobs.find((job) => job.id === jobId);
  return job ? job.products : null;
}

/**
 * Get customer details.
 * @returns {Object} Customer details including name and number.
 */
export function getCustomerDetails() {
  return dataStore.customer;
}

export default dataStore;
