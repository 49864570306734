import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA_P_jHx-fJGhrnfFQbJ28-W21djYh5EVo',
  authDomain: 'studioflow-main.firebaseapp.com',
  projectId: 'studioflow-main',
  storageBucket: 'studioflow-main.appspot.com',
  messagingSenderId: '254627856323',
  appId: '1:254627856323:web:6faa1a48eec35493e3c776',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
