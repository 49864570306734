import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// Define branded theme with a space-age color palette
export const brandedTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0ff', // Neon cyan for a futuristic feel
    },
    secondary: {
      main: '#ff0', // Bright yellow as an accent
    },
    background: {
      default: '#1a1a1a', // Darker background for contrast
      paper: '#212121', // Slightly lighter gray for components
    },
    text: {
      primary: '#e0e0e0', // Light gray text on dark backgrounds
      secondary: '#7c7c7c', // Muted gray for secondary text
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Modern sans-serif font
    h4: {
      fontWeight: 700,
      color: '#0ff', // Neon cyan for headers
    },
    button: {
      fontWeight: 600,
      color: '#fff',
    },
  },
});
