import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Stack,
  Typography,
  Button,
  IconButton,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import JobDetailDialog from './JobDetailDialog';
import StudioVaultDialog from './StudioVaultDialog';
import { getJobList, getJobWorkflow } from '../data/dataStore';

function JobList({ isAdmin, onVaultOpen }) {
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [openStudioVaultDialog, setOpenStudioVaultDialog] = useState(false);
  const [vaultSource, setVaultSource] = useState('');
  const [selectedStep, setSelectedStep] = useState(null);

  const statusIcons = {
    complete: <CheckCircleIcon color="success" />,
    inProgress: <HourglassEmptyIcon color="warning" />,
    default: <RadioButtonUncheckedIcon color="disabled" />,
  };

  useEffect(() => {
    setJobList(getJobList());
  }, []);

  const handleRowClick = (job) => {
    const workflow = getJobWorkflow(job.id);
    if (workflow) {
      setSelectedJob({ ...job, workflow });
      setOpenJobDialog(true);
    }
  };

  const getStatusIcon = (status) => statusIcons[status] || statusIcons.default;

  const calculateTimeSpent = (start, end = new Date()) => {
    const timeDiff = end - start;
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    return `(${days ? `${days} days ` : ''}${hours ? `${hours} hours ` : ''}${minutes ? `${minutes} mins` : ''})`;
  };

  const handleStudioVaultOpen = (source, job) => {
    setSelectedJob(job);
    setVaultSource(source);
    setOpenStudioVaultDialog(true);
  };

  return (
    <Box sx={{ maxHeight: '67vh', overflowY: 'auto', padding: '10px' }}>
      {jobList.map((job) => {
        const workflow = getJobWorkflow(job.id) || [];
        const publishStep = workflow.find((step) => step.name === 'Publish');
        const isStudioVaultButtonDisabled = publishStep?.progress === '0/20';

        return (
          <Paper
            key={job.id}
            onClick={() => handleRowClick(job)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 2,
              padding: 1,
              boxShadow: 3,
              cursor: 'pointer',
              '&:hover': { backgroundColor: 'action.hover', boxShadow: 6 },
              transition: 'background-color 0.2s ease, box-shadow 0.2s ease',
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={{
                  minWidth: '200px',
                  maxWidth: '200px',
                  textAlign: 'left',
                  marginRight: 2,
                }}
              >
                <Typography
                  variant="subtitle1"
                  noWrap
                  sx={{ fontWeight: 'bold' }}
                >
                  Job: {job.id}
                </Typography>
                <Typography variant="caption" color="textSecondary" noWrap>
                  {job.description}
                </Typography>
                <Typography variant="caption">{job.date}</Typography>
              </Box>

              {workflow.map((step, index) => (
                <Paper
                  key={index}
                  elevation={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedStep(step);
                  }}
                  sx={{
                    minWidth: '100px',
                    height: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 1,
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      boxShadow: 4,
                    },
                    transition:
                      'background-color 0.2s ease, box-shadow 0.2s ease',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                      marginBottom: 1,
                    }}
                  >
                    <IconButton size="small" sx={{ padding: 0 }}>
                      {getStatusIcon(step.status)}
                    </IconButton>
                    {step.showAlarm && (
                      <Tooltip
                        title={`Missed Deadline of ${step.missedDeadline}`}
                        arrow
                      >
                        <AccessAlarmIcon fontSize="small" color="error" />
                      </Tooltip>
                    )}
                    {step.showAction && step.name === 'Approval' && (
                      <Tooltip title="There are items ready to approve." arrow>
                        <AssignmentLateIcon fontSize="small" color="warning" />
                      </Tooltip>
                    )}
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
                  >
                    {step.name}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {step.progress}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(parseInt(step.progress.split('/')[0]) / 20) * 100}
                    sx={{
                      width: '100%',
                      height: 5,
                      marginTop: 1,
                      borderRadius: 2,
                    }}
                  />
                </Paper>
              ))}
            </Stack>
            <Button
              variant="contained"
              disabled={isStudioVaultButtonDisabled}
              onClick={(e) => {
                e.stopPropagation();
                handleStudioVaultOpen('JobList', job);
              }}
            >
              StudioVault
            </Button>
          </Paper>
        );
      })}

      {selectedJob && (
        <JobDetailDialog
          open={openJobDialog}
          onClose={() => setOpenJobDialog(false)}
          job={selectedJob}
        />
      )}
      <StudioVaultDialog
        open={openStudioVaultDialog}
        onClose={() => setOpenStudioVaultDialog(false)}
        job={selectedJob}
        source={vaultSource}
      />
      {selectedStep && (
        <Dialog open={!!selectedStep} onClose={() => setSelectedStep(null)}>
          <DialogTitle>{selectedStep.name} - Status History</DialogTitle>
          <DialogContent>
            {selectedStep.statusHistory?.length > 0 ? (
              <List>
                {selectedStep.statusHistory.map((status, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${status.status} - ${new Date(status.timestamp).toLocaleString()} ${index < selectedStep.statusHistory.length - 1 ? calculateTimeSpent(new Date(status.timestamp), new Date(selectedStep.statusHistory[index + 1].timestamp)) : ''}`}
                      secondary={`User: ${status.user}, Comment: ${status.comment}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No status records exist yet.
              </Typography>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default JobList;
