import React, { useState, useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import Login from './components/Login';
import AdminMainPage from './components/AdminMainPage';
import CustomerMainPage from './components/CustomerMainPage';
import { lightTheme, darkTheme, brandedTheme } from './theme';
import ErrorBoundary from './components/ErrorBoundary';

function App() {
  const [themeMode, setThemeMode] = useState('light');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const theme = useMemo(() => {
    const themes = {
      light: lightTheme,
      dark: darkTheme,
      branded: brandedTheme,
    };
    return createTheme(themes[themeMode] || lightTheme);
  }, [themeMode]);

  const navigate = useNavigate();

  const toggleTheme = () => {
    setThemeMode((prevMode) =>
      prevMode === 'light' ? 'dark' : prevMode === 'dark' ? 'branded' : 'light'
    );
  };

  const handleMenuClose = () => setMenuAnchor(null);

  const handleDialogToggle = (setDialogState) => () => {
    setDialogState((prev) => !prev);
    handleMenuClose();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout failed: ', error);
    }
    handleMenuClose();
  };

  const themeSwitchLabel =
    themeMode === 'light' ? 'Dark' : themeMode === 'dark' ? 'Branded' : 'Light';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => setMenuAnchor(e.currentTarget)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              StudioFlow
            </Typography>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDialogToggle(setIsAboutOpen)}>
            About StudioFlow
          </MenuItem>
          <MenuItem onClick={handleDialogToggle(setIsSettingsOpen)}>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Dialog open={isAboutOpen} onClose={handleDialogToggle(setIsAboutOpen)}>
          <DialogTitle>About StudioFlow</DialogTitle>
          <DialogContent>
            <Typography>
              StudioFlow is an application designed to manage and streamline
              your studio workflows.
            </Typography>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isSettingsOpen}
          onClose={handleDialogToggle(setIsSettingsOpen)}
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent>
            <Button onClick={toggleTheme}>
              Switch to {themeSwitchLabel} Mode
            </Button>
          </DialogContent>
        </Dialog>

        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/admin" element={<AdminMainPage />} />
            <Route path="/customer" element={<CustomerMainPage />} />
          </Routes>
        </ErrorBoundary>

        <div style={{ textAlign: 'center', padding: '10px' }}>
          <Typography
            variant="body1"
            onClick={toggleTheme}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              marginTop: '-50px',
            }}
          >
            Switch to {themeSwitchLabel} Mode
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
