import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import JobList from './JobList';
import { getCustomerDetails } from '../data/dataStore';

function CustomerMainPage() {
  const customer = getCustomerDetails();

  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: useTheme().palette.background.default,
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          StudioFlow
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {customer.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.number}
        </Typography>
      </Box>
      <JobList
        isAdmin={false}
        onVaultOpen={(source, job) =>
          console.log(`StudioVault opened from ${source} with job:`, job)
        }
      />
    </Box>
  );
}

export default CustomerMainPage;
