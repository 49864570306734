import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Stack,
  Button,
  IconButton,
  TextField,
  Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getJobProducts } from '../data/dataStore';

const fetchAvailableImages = async (productName) => {
  const availableImages = [];
  const maxImages = 20;

  for (let index = 0; index < maxImages; index++) {
    const imagePath = `${process.env.PUBLIC_URL}/images/${productName}${index === 0 ? '' : `_${index}`}.jpeg`;
    const image = new Image();
    image.src = imagePath;

    const imageLoaded = await new Promise((resolve) => {
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
    });

    if (imageLoaded) {
      availableImages.push(imagePath);
    } else {
      break;
    }
  }
  return availableImages;
};

function StudioVaultDialog({ open, onClose, job, source, row }) {
  const [productList, setProductList] = useState([]);
  const [selectedImages, setSelectedImages] = useState(new Set());
  const [filters, setFilters] = useState({
    sku: '',
    name: '',
    collection: job ? job.description : '',
  });
  const [imageCache, setImageCache] = useState({});
  const [largeImage, setLargeImage] = useState(null);
  const imageCacheRef = useRef({});

  useEffect(() => {
    if (
      job &&
      (source === 'CustomerMainPage' ||
        source === 'AdminMainPage' ||
        source === 'JobList')
    ) {
      const products = job.products || getJobProducts(job.id);
      setProductList(products || []);
    } else if (row && source === 'JobDetailDialog') {
      setProductList([row]);
    }
  }, [job, row, source]);

  useEffect(() => {
    const loadImages = async () => {
      const newImageCache = { ...imageCacheRef.current };

      for (const product of productList) {
        if (!newImageCache[product.name]) {
          newImageCache[product.name] = await fetchAvailableImages(
            product.name
          );
        }
      }
      imageCacheRef.current = newImageCache;
      setImageCache(newImageCache);
    };

    if (productList.length > 0) {
      loadImages();
    }
  }, [productList]);

  const handleImageClick = (image) => {
    setSelectedImages((prev) => {
      const updated = new Set(prev);
      updated.has(image) ? updated.delete(image) : updated.add(image);
      return updated;
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const filteredProducts = productList.filter(
    (product) =>
      (filters.sku === '' || product.sku.includes(filters.sku)) &&
      (filters.name === '' ||
        product.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.collection === '' ||
        job.description
          .toLowerCase()
          .includes(filters.collection.toLowerCase()))
  );

  const handleSelectAll = () => {
    const allImages = new Set(
      filteredProducts.flatMap((product) => imageCache[product.name] || [])
    );
    setSelectedImages(allImages);
  };

  const handleDeselectAll = () => {
    setSelectedImages(new Set());
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ height: '80vh', margin: 'auto' }}
    >
      <DialogTitle>
        StudioVault
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" align="center" gutterBottom>
          StudioVault - Product Images
        </Typography>

        <Box display="flex" justifyContent="space-between" mb={3}>
          <TextField
            label="SKU"
            name="sku"
            value={filters.sku}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Product Name"
            name="name"
            value={filters.name}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <TextField
            label="Collection"
            name="collection"
            value={filters.collection}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <Button variant="outlined" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button variant="outlined" onClick={handleDeselectAll}>
            Deselect All
          </Button>
        </Box>

        <Box sx={{ maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => {
              const images = imageCache[product.name] || [];

              return (
                <Box
                  key={index}
                  sx={{
                    marginBottom: 3,
                    padding: 2,
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    boxShadow: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: 'text.secondary' }}
                    >
                      SKU: {product.sku}
                    </Typography>
                  </Box>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ marginLeft: 'auto' }}
                  >
                    {images.map((image, idx) => (
                      <Box
                        key={idx}
                        component="div"
                        sx={{
                          position: 'relative',
                          width: 70,
                          height: 70,
                          borderRadius: 1,
                          border: '1px solid #ddd',
                          cursor: 'pointer',
                          backgroundColor: selectedImages.has(image)
                            ? 'rgba(0, 123, 255, 0.25)'
                            : 'transparent',
                        }}
                        onClick={() => handleImageClick(image)}
                        onDoubleClick={() => setLargeImage(image)} // Double click to view large version
                      >
                        <img
                          src={image}
                          alt={image}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: 4,
                            opacity: selectedImages.has(image) ? 0.7 : 1,
                          }}
                        />
                        {selectedImages.has(image) && (
                          <CheckCircleIcon
                            sx={{
                              position: 'absolute',
                              top: -4,
                              right: -4,
                              color: 'blue',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              );
            })
          ) : (
            <Typography variant="body2" color="textSecondary">
              No products available to display images.
            </Typography>
          )}
        </Box>

        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            onClick={() =>
              alert(
                'Images to be downloaded:\n' +
                  Array.from(selectedImages).join('\n')
              )
            }
          >
            Download Selected
          </Button>
        </Box>
      </DialogContent>

      <Modal
        open={!!largeImage}
        onClose={() => setLargeImage(null)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '80%',
            height: '80%',
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <img
            src={largeImage}
            alt="Large view"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          <Button
            onClick={() => setLargeImage(null)}
            sx={{ mt: 2, width: '100%' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Dialog>
  );
}

export default StudioVaultDialog;
