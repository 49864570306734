import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, useTheme } from '@mui/material';
import JobList from './JobList';
import { getCustomerDetails } from '../data/dataStore';

function AdminMainPage() {
  const theme = useTheme();
  const customer = getCustomerDetails();
  const [openAdminDialog, setOpenAdminDialog] = useState(false);

  const handleVaultOpen = (source, job) =>
    console.log(`StudioVault opened from ${source} with job:`, job);

  return (
    <Box
      sx={{
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" gutterBottom>
          StudioFlow Admin
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {customer.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {customer.number}
        </Typography>
      </Box>

      <JobList isAdmin={true} onVaultOpen={handleVaultOpen} />

      <Dialog open={openAdminDialog} onClose={() => setOpenAdminDialog(false)}>
        <DialogTitle>Admin functionality coming soon</DialogTitle>
      </Dialog>
    </Box>
  );
}

export default AdminMainPage;
