import React, { useState, useEffect } from 'react';
import { getJobProducts, getJobWorkflow } from '../data/dataStore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Paper,
  Stack,
  IconButton,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StudioVaultDialog from './StudioVaultDialog';

const fixedSteps = [
  { name: 'Sample Check in', id: 'checkin' },
  { name: 'Capture', id: 'capture' },
  { name: 'Retouch', id: 'retouch' },
  { name: 'QA Internal', id: 'qa' },
  { name: 'Approval', id: 'approval' },
  { name: 'Publish', id: 'publish' },
  { name: 'Sample Check out', id: 'checkout' },
];

function JobDetailDialog({ open, onClose, job }) {
  const [approvalStatus, setApprovalStatus] = useState({});
  const [openStudioVaultDialog, setOpenStudioVaultDialog] = useState(false);
  const [vaultSource, setVaultSource] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [products, setProducts] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [jobTitle, setJobTitle] = useState(job?.description || '');

  useEffect(() => {
    if (job?.id) {
      setProducts(getJobProducts(job.id));
      setWorkflow(getJobWorkflow(job.id));
      setJobTitle(job.description);
    }
  }, [job]);

  useEffect(() => {
    if (workflow.length) {
      const initialApprovalStatus = products.reduce((acc, _, index) => {
        const approvalStep = workflow.find((step) => step.name === 'Approval');
        acc[index] =
          approvalStep && parseInt(approvalStep.progress.split('/')[0]) > index;
        return acc;
      }, {});
      setApprovalStatus(initialApprovalStatus);
    }
  }, [products, workflow]);

  const toggleApprovalStatus = (index) =>
    setApprovalStatus((prev) => ({ ...prev, [index]: !prev[index] }));

  const handleStudioVaultOpen = (source, name, sku) => {
    setVaultSource(source);
    setSelectedRow({ name, sku, job });
    setOpenStudioVaultDialog(true);
  };

  const handleAddProduct = () => {
    setProducts((prevProducts) => [
      ...prevProducts,
      { name: 'New Product', sku: '', status: 'active' },
    ]);
  };

  const handleEditToggle = (e) => {
    e.stopPropagation();
    setEditMode((prev) => !prev);
  };

  if (!job) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{ height: '90vh', margin: 'auto' }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '1.5rem',
        }}
      >
        <IconButton onClick={handleEditToggle} sx={{ marginRight: 'auto' }}>
          <EditIcon color={editMode ? 'primary' : 'inherit'} />
        </IconButton>

        <Box sx={{ textAlign: 'center', flexGrow: 1, maxWidth: '60%' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Job ID: {job.id}
          </Typography>
          {editMode ? (
            <TextField
              variant="outlined"
              fullWidth
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              sx={{ fontSize: '1.5rem', textAlign: 'center', mt: 1 }}
            />
          ) : (
            <Typography variant="h6" sx={{ mt: 1 }}>
              {jobTitle}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ display: 'block', mt: 0.5 }}
          >
            {job.date}
          </Typography>
        </Box>

        <IconButton
          onClick={onClose}
          sx={{ marginLeft: 'auto', color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ maxHeight: '70vh', overflowY: 'auto', padding: '10px' }}>
          {products.map((product, index) => {
            const isPublishStepActive = workflow.some(
              (step) =>
                step.name === 'Publish' &&
                ['inProgress', 'complete'].includes(step.status)
            );

            return (
              <Paper
                key={`${job.id}-${index}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 2,
                  padding: 2,
                  boxShadow: 3,
                  '&:hover': { backgroundColor: 'action.hover', boxShadow: 6 },
                  transition:
                    'background-color 0.2s ease, box-shadow 0.2s ease',
                }}
              >
                <Box sx={{ minWidth: '200px', marginRight: 2 }}>
                  {editMode ? (
                    <>
                      <TextField
                        variant="standard"
                        fullWidth
                        label="Product Name"
                        value={product.name}
                        onChange={(e) => {
                          const updatedProducts = [...products];
                          updatedProducts[index].name = e.target.value;
                          setProducts(updatedProducts);
                        }}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        label="SKU"
                        value={product.sku}
                        onChange={(e) => {
                          const updatedProducts = [...products];
                          updatedProducts[index].sku = e.target.value;
                          setProducts(updatedProducts);
                        }}
                        sx={{ mt: 1 }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {product.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                      >
                        SKU: {product.sku}
                      </Typography>
                    </>
                  )}
                </Box>

                <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
                  {fixedSteps.map((step) => {
                    const isCompleted = workflow.some(
                      (wfStep) =>
                        wfStep.name === step.name &&
                        wfStep.status === 'complete'
                    );
                    return (
                      <Box
                        key={step.id}
                        sx={{
                          flexGrow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          textAlign: 'center',
                          minWidth: 0,
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (step.name === 'Approval')
                              toggleApprovalStatus(index);
                          }}
                          sx={{ padding: 0 }}
                        >
                          {step.name === 'Approval' ? (
                            approvalStatus[index] ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <RadioButtonUncheckedIcon color="disabled" />
                            )
                          ) : isCompleted ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <RadioButtonUncheckedIcon color="disabled" />
                          )}
                        </IconButton>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'bold' }}
                        >
                          {step.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>

                {editMode && (
                  <Tooltip title="Mark as Removed">
                    <ToggleButtonGroup
                      value={
                        product.status === 'removed' ? 'removed' : 'active'
                      }
                      exclusive
                      onChange={() => {
                        const updatedProducts = [...products];
                        updatedProducts[index].status =
                          updatedProducts[index].status === 'removed'
                            ? 'active'
                            : 'removed';
                        setProducts(updatedProducts);
                      }}
                      sx={{ ml: 2 }}
                    >
                      <ToggleButton value="removed">
                        <DeleteIcon
                          color={
                            product.status === 'removed' ? 'error' : 'disabled'
                          }
                        />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                )}

                <Button
                  variant="contained"
                  disabled={!isPublishStepActive}
                  onClick={() =>
                    handleStudioVaultOpen(
                      'JobDetailDialog',
                      product.name,
                      product.sku
                    )
                  }
                >
                  StudioVault
                </Button>
              </Paper>
            );
          })}

          {editMode && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddProduct}
              sx={{ marginTop: 2 }}
            >
              Add Product
            </Button>
          )}
        </Box>
      </DialogContent>

      <StudioVaultDialog
        open={openStudioVaultDialog}
        onClose={() => setOpenStudioVaultDialog(false)}
        job={job}
        source={vaultSource}
        row={selectedRow}
      />
    </Dialog>
  );
}

export default JobDetailDialog;
