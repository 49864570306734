import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { TextField, Button, Box, Typography } from '@mui/material';

function Login() {
  const [customerId, setCustomerId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, username, password);
      if (customerId) {
        navigate('/customer');
      } else {
        navigate('/admin');
      }
    } catch (error) {
      setError('Login failed: ' + error.message);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      minHeight="33vh" // One-third of viewport height
      pt={5} // Padding at the top for positioning within the third
      sx={{ bgcolor: 'background.default', color: 'text.primary' }}
    >
      <Typography variant="h4" gutterBottom>
        StudioFlow
      </Typography>
      <TextField
        label="Customer ID"
        variant="outlined"
        value={customerId}
        onChange={(e) => setCustomerId(e.target.value)}
        sx={{ mb: 2, width: '300px' }}
      />
      <TextField
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        sx={{ mb: 2, width: '300px' }}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 2, width: '300px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        sx={{ width: '300px' }}
      >
        Login
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default Login;
